import React from "react";

import SilverHero from "../components/silver/SilverHero";
import SilverTop from "../components/silver/SilverTop";
import SilverBot from "../components/silver/SilverBot";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Silver() {
    return (
        <>
        <SilverHero />
        <SilverTop />
        <SilverBot />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Silver;