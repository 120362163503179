import React from 'react';

export default function BronzeBot() {
    return (
        <div style={{paddingTop: 75, paddingBottom: 150, paddingLeft: 150, paddingRight: 150, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 75, display: 'inline-flex'}}>
    
    <div style={{alignSelf: 'stretch', paddingBottom: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex'}}>
        <div style={{width: 565, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
            <div style={{alignSelf: 'stretch', height: 62, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Outcomes</div>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>By the end of the Silver course, students will be able to:</div>
            </div>
            <div style={{alignSelf: 'stretch', height: 446, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Master the art of designing algorithms for various problem types, including dynamic programming, graph algorithms, and data structures</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Learn basic graph traversal algorithms (BFS, DFS)</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Develop the skill to reduce complex problems into simpler sub-problems and apply divide-and-conquer strategies.</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Explore bitwise operators (AND, OR, XOR, shifting) and their applications in solving problems involving binary representation and manipulation.</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Learn to manipulate complex data structures, including trees, graphs, and arrays, to solve intricate problems.</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Solve a variety of real USACO Silver-level problems to reinforce your skills and knowledge.</div>
                </div>
            </div>
        </div>
        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex'}}>
            <div style={{width: 418, height: 175, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', height: 85, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Stats</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Ilim offers tremendous value to our students. Foundations alone consists of:</div>
                </div>
                <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>100K</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Lines of code</div>
                    </div>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>42hr</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Of lecture content</div>
                    </div>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>14</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Assignments</div>
                    </div>
                </div>
            </div>
            <div style={{alignSelf: 'stretch', height: 197, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Sample Materials</div>
                <div style={{alignSelf: 'stretch', height: 148, padding: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{padding: 20, background: '#FFE500', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 135, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>course syllabus</div>
                    </div>
                    <div style={{width: 175, padding: 20, background: '#FFE500', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 117, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>course slides</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
        <div style={{alignSelf: 'stretch', height: 62, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Schedule</div>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>See the topics covered and schedule for the Foundations course below.</div>
        </div>
        <div style={{alignSelf: 'stretch',padding: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 1</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Course Orientation</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intro to Prefix Sums</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Prefix Sums</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 2</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Custom Comparators</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Coordinate Compression</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Prefix Sums Quiz / Two Pointers</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 3</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Two Pointers Practice</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Utilizing Sets/Maps/Multisets</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Two Pointers Quiz</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 4</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Advanced Greedy Algorithms</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Binary Search</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Advanced Binary Search Practice</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 5</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Binary Search Quiz</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Graph Traversal (DFS)</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Graph Traversal (BFS)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 6</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Tree Algorithms / Flood Fill</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Tree Algorithms / Flood Fill 2</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Graph and Tree Quiz</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 7</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Functional Graphs</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Functional Graphs Practice</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Functional Graphs Quiz / Bitwise Operators Intro</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BACED2', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 8</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Practice Bitwise Operators</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Review for Final</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Final Exam</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{alignSelf: 'stretch', height: 97, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Ready to level-up your math and programming skills?</div>
            <a href="https://buy.stripe.com/9AQ5nU4nV0Ug45G5km" className='button-cr' style={{textDecoration: 'none'}}>
                <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>register now</div>
            </a>
        </div>
    </div>
    
</div>
    );
}