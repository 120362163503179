import React from "react";

import PageHero from '../components/home/PageHero';
import SplitInfos from '../components/home/SplitInfos';
import SplitInfos2 from '../components/home/SplitInfos2';
import IslandAbout2 from "../components/home/IslandAbout2";
import SplitInfos3 from "../components/home/SplitInfos3";
import JoinUs from '../components/home/JoinUs';
import Footer from "../components/home/Footer";

function About() {
    return (
        <>
        <PageHero />
        <SplitInfos />
        <SplitInfos2 />
        <IslandAbout2 />
        <SplitInfos3 />
        <JoinUs />
        <Footer />
        </>
    );
}

export default About;