import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./components/home/HeaderMenu/Navbar";
import About from "./pages/about";
import Bronze from "./pages/bronze";
import Silver from "./pages/silver";
import Gold from "./pages/gold";
import Platinum from "./pages/platinum";
import Foundations from "./pages/foundations";
import Programs from "./pages/programs";
import Tutoring from "./pages/tutoring";
import JoinThis from "./pages/jointhis";
import Home from "./pages/homepage";

export default function App() {
  return (
    <div className='App'>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/bronze" element={<Bronze />} />
            <Route path="/silver" element={<Silver />} />
            <Route path="/gold" element={<Gold />} />
            <Route path="/platinum" element={<Platinum />} />
            <Route path="/foundations" element={<Foundations />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/tutoring" element={<Tutoring />} />
            <Route path="/jointhis" element={<JoinThis />} />
        </Routes>
        </BrowserRouter>


    </div>
  );
}
