import React from 'react';

export default function GoldTop() {
    return (
        <div style={{paddingTop: 50, paddingBottom: 100, paddingLeft: 150, paddingRight: 150, background: '#E7BC4D', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex', filter: 'blur(3px)'}}>
    <div style={{flex: '1 1 0', alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 184, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Congue mauris rhoncus aenean vel. Adipiscing diam donec adipiscing tristique risus nec feugiat in. </div>
        </div>
        <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Program cost:</div>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '400', textTransform: 'lowercase', wordWrap: 'break-word'}}>$1,500</div>
        </div>
    </div>
    <div style={{width: 425, alignSelf: 'stretch', padding: 50, background: '#E1F1FD', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', borderRight: '1px black solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 263, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
            <div style={{color: '#002626', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Quick Facts:</div>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Commitment:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:          </div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Dates:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Location:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Capacity:</div>
                </div>
                <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Beginner II</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures          </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>June 1st - June 1st</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>MWF: 6:00PM - 8:00PM</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Video Conference</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>50</div>
                </div>
            </div>
        </div>
        <div style={{padding: 20, background: '#FFE500', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
            <div style={{width: 116, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>register now</div>
        </div>
    </div>
</div>
    );
}