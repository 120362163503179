import React from 'react';

export default function CourseGrid() {
    return (
        <div style={{width: '100%', height: '1250px', paddingTop: 50, paddingBottom: 150, paddingLeft: 150, paddingRight: 150, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 50, display: 'flex', background: '#002626', boxSizing: 'border-box'}}>
    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex'}}>
        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '400', textTransform: 'lowercase', wordWrap: 'break-word'}}>tailored to your needs</div>
        <div style={{color: 'white', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Explore our extensive offerings</div>
    </div>
    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
        <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#95C623', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>C++ Crash Course</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Beginner I</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>A brief crash course on C++ covering the basics of variables, loops, and arrays.  This comes free with the purchase of any course and is completely optional</div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#43ABFF', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Foundations</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Beginner I</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Foundations course lays the groundwork for students to excel in computer science, equipping them with the skills and knowledge needed to solve AP CSA-level problems with confidence. </div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#BD7F46', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Bronze</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Beginner II</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Bronze course elevates students' skills to tackle USACO Bronze-level problems. Through rigorous training, participants develop a strong foundation in problem-solving strategies.</div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#BACED2', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Silver</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intermediate I</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Silver course covers topics like prefix sums, binary search, and graph theory. Students master these advanced techniques to confidently solve USACO Silver-level problems.</div>
                </div>
            </div>
        </div>
        <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#E7BC4D', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Gold</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intermediate II</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Gold course delves into dynamic programming, advanced graph theory, and higher-level algorithms. Students master these techniques to excel in USACO Gold-level challenges</div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#507A8B', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Platinum</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Advanced</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>In our Platinum course, students tackle advanced topics such as range queries, sophisticated dynamic programming techniques, and intricate tree structures.</div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: '#293F75', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Private Tutoring</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Varies</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Students receive personalized guidance to address their unique needs. Tutors offer focused support to improve competitive programming skills and prepare for technical interviews.</div>
                </div>
            </div>
            <div style={{width: 270, paddingTop: 10, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 184, paddingTop: 30, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, background: 'black', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Technical Interview Preparation</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}></div>
                </div>
                <div style={{alignSelf: 'stretch', height: 156, paddingLeft: 10, paddingRight: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time Commitment:</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:</div>
                        </div>
                        <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Varies</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                            <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>21 lectures</div>
                        </div>
                    </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Students receive expert guidance to excel in technical interviews. We hold mock interviews, preparing students for success at top tech companies.</div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}