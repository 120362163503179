import React from 'react';

export default function HomeHero() {
    return (
        <div className='main-hero'>
            <div className='hero-text'>
                Fast-paced, intensive competition programming summer camps for middle through high school.
            </div>
        </div>
    );
}