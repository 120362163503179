import React from 'react';

export default function Offerings() {
    return (
        <div style={{width: '100%', height: '100%', paddingTop: 50, paddingBottom: 50, paddingLeft: 100, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex', background: '#002626', boxSizing: 'border-box'}}>
    <div style={{height: 588, paddingTop: 75, paddingBottom: 100, paddingLeft: 100, paddingRight: 150, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', borderLeft: '5px #95C623 solid', borderTop: '5px #95C623 solid', borderBottom: '5px #95C623 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 70, display: 'flex'}}>
        <div style={{alignSelf: 'stretch', color: '#95C623', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Our programs include</div>
        <div style={{alignSelf: 'stretch', height: 297, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 75, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'inline-flex'}}>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Personalized Mentorship</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Each student receives personalized attention and guidance from our experienced instructors, ensuring that their individual learning needs are met and their progress is maximized.</div>
                    </div>
                </div>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Custom Problem Sets</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>We offer a curated selection of problem sets designed specifically to challenge and reinforce understanding of key concepts, providing a tailored learning experience that goes beyond generic exercises.</div>
                    </div>
                </div>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Interactive Learning Environment</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Our program fosters active participation through interactive lectures, live coding sessions, and collaborative problem-solving workshops, creating an engaging atmosphere that promotes deeper learning and understanding.</div>
                    </div>
                </div>
            </div>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'inline-flex'}}>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Advanced Techniques and Strategies</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>In addition to covering fundamental concepts, we delve into advanced techniques and strategies used by top competitors, equipping students with the tools they need to tackle even the most challenging problems with confidence.</div>
                    </div>
                </div>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Real-world Applications</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>We emphasize the practical application of programming skills beyond competitions, providing insights into how these skills are utilized in industry settings and real-world problem-solving scenarios.</div>
                    </div>
                </div>
                <div style={{flex: '1 1 0', height: 111, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 30, height: 30, position: 'relative'}}>
                            <div style={{width: 30, height: 30, left: 0, top: 0, position: 'absolute', background: 'white'}}></div>
                        </div>
                    </div>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Community Support</div>
                        <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Students benefit from a supportive community of like-minded peers, where they can collaborate, share insights, and motivate each other to achieve their goals. This network provides invaluable support and encouragement throughout their programming journey.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}