import React from 'react';

import TextItem from './TextItem';

export default function WhyCP() {
    return (
        <div className='why-cp'>
            <div className='columns-cp'>
                <div className='left-text-cp'>
                    <div className='text-content-cp'>
                        <div className='header-cp'>
                            Why competition programming?
                        </div>
                        <div className='text-items-cp'>
                            <TextItem 
                                header={'Gain problem-solving skills'}
                                content={'The goals of this course does not only include teaching students how to code, but also how to think. Students will learn how to break down complex problems into smaller, more manageable parts.'}
                            />
                            <TextItem 
                                header={'Increase earning potential'}
                                content={'It is shown that many companies, such as Google, Meta, and Amazon, value problem-solving skills. By learning competition programming, students will be able to increase their chances of getting hired by these companies.'}
                            />
                        </div>
                    </div>
                    <a href="/programs" className='button-cp' style={{ textDecoration: 'none' }}>
                        <div className='button-text-cp'>
                            Explore our programs
                        </div>
                    </a>
                </div>
                <div className='right-media-cp'>

                </div>
            </div>
        </div>
    );
}