import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Navbar.css";
import {navItems} from "./NavItems";
import Dropdown from "./Dropdown";
import Logo from "../../../assets/svg/logo_wide_v2.svg"

function Navbar() {
    const [dropdown, setDropdown] = useState(false);
    return (
        <>
          <div className="logo-container">
            <a href="/" style={{ display: 'inline-block' }}>
                <img src={Logo} alt="ilim"/>
            </a>
          </div>

          <nav className="navbar">
            <ul className="nav-items">
                {navItems.map((item) => {
                    if(item.title === "programs") {
                        return (
                            <li
                              key={item.id}
                              className={item.cName}
                              onMouseEnter={() => setDropdown(true)}
                              onMouseLeave={() => setDropdown(false)}
                            >
                              <Link to={item.path}>{item.title}</Link>
                              {dropdown && <Dropdown />}
                            </li>
                          );
                        }
                    return (
                        <li key={item.id} className={item.cName}>
                            <Link to={item.path}>{item.title}</Link>
                        </li>
                    );
                })}
            </ul>
          </nav>
        </>
    );
}

export default Navbar;