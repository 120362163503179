import React from 'react';

export default function JoinHero() {
    return (
        <div style={{width: '100%', height: 'fit-content', paddingLeft: 150, paddingRight: 150, paddingTop: 100, paddingBottom: 100, background: '#002626', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150, display: 'flex', boxSizing: 'border-box'}}>
      <div style={{width: '200', color: '#E1F1FD', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Ilim Ambassador</div>
      <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'flex'}}>
          <div style={{alignSelf: 'stretch', height: 121, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
              <div style={{color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Become an Ilim Ambassador!</div>
              <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Join us as an Ilim Ambassador and promote our competitive programming camp within your school community! Spread the word, organize events, and earn community service hours or compensation for your efforts. Inspire the next generation of programmers and make a difference. Apply now! </div>
          </div>
            <a href="https://forms.gle/pKViJbUKWLtPn2iUA" className='button-cr' style={{textDecoration: 'none', marginTop: '60px'}}>
                <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>apply</div>
            </a>
      </div>
  </div>
    );
}