import React from 'react';

export default function JoinItem({ title, text, icon }) {
    return (
        <div className='join-item'>
            <div className='button-icon-join-item'>
                <div className='left-content-join-item'>
                    <div className='icon-join-item'>
                        <img src={icon} />
                    </div>
                </div>
                <div className='content-join-item'>
                    <div className='header-join-item'>
                        {title}
                    </div>
                    <div className='text-join-item'>
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
}