import React from 'react';

import CMU from '../../assets/img/cmu.png';
import NEU from '../../assets/img/neu-logo.png';
import HAR from '../../assets/img/harvard.png';
import MIT from '../../assets/img/mit.png';
import STAN from '../../assets/img/stan.png';

export default function JoinHero() {
    return (
        <div style={{paddingLeft: 150, paddingRight: 150, paddingTop: 100, paddingBottom: 100, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 75, display: 'inline-flex'}}>
    <div style={{alignSelf: 'stretch', height: 427, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50, display: 'flex'}}>
        <div style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Join our teaching staff</div>
        <div style={{width: 1140, height: 121, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Become a Tutor at Ilim!</div>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Join us as a tutor for all levels of our competitive programming courses! Inspire the next generation of programmers with your expertise and passion. Apply now to be part of our dynamic team dedicated to empowering students at every stage of their coding journey.</div>
        </div>
        <div style={{paddingTop: 20, paddingBottom: 20, paddingLeft: 30, paddingRight: 20, background: '#E1F1FD', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, justifyContent: 'flex-start', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
            <img style={{width: 100, height: 100}} src={CMU} />
            <img style={{width: 100, height: 100}} src={NEU}/>
            <img style={{width: 124.09, height: 105}} src={HAR} />
            <img style={{width: 65.48, height: 100}} src={STAN} />
            <img style={{width: 180, height: 120}} src={MIT} />
        </div>
    </div>
    <a href="https://forms.gle/S45tjvxn9nWBC9BP7" className='button-cr' style={{textDecoration: 'none'}}>
                <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>apply</div>
            </a>
</div>
    );
}