import React from "react";

import HomeHero from '../components/home/HomeHero';
import IslandAbout from '../components/home/IslandAbout';
import CourseRow from '../components/home/CourseRow';
import WhyCP from '../components/home/WhyCp';
import StatsBanner from '../components/home/StatsBanner';
import TutoringInfo from '../components/home/TutoringInfo';
import JoinUs from '../components/home/JoinUs';
import Footer from '../components/home/Footer';

function HomePage() {
    return (
        <><HomeHero /><IslandAbout /><CourseRow /><WhyCP /><StatsBanner /><TutoringInfo /><JoinUs /><Footer /></>
    );
}

export default HomePage;