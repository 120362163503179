import React from 'react';

export default function IslandAbout2() {
    return (
        <div style={{width: '100%', height: '100%', paddingLeft: 150, paddingRight: 150, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex', background: '#002626', boxSizing: 'border-box'}}>
        <div style={{height: 445, paddingLeft: 100, paddingRight: 100, paddingTop: 60, paddingBottom: 60, background: '#95C623', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', height: 251, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50, display: 'flex'}}>
                <div style={{textAlign: 'center', color: '#002626', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Why Ilim Code Camp?</div>
                <div style={{alignSelf: 'stretch', color: '#141A24', fontSize: 28, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Choosing us means opting for a premium learning experience tailored to your success. What sets us apart is our unwavering dedication to each student, ensuring personalized attention and support throughout your journey. Unlike larger programs, we prioritize quality over quantity, guaranteeing that every student receives the guidance they need to excel. Our uniqueness extends to our meticulously crafted curriculum, featuring custom problem sets and in-depth learning methods designed to solidify your understanding of key concepts. </div>
            </div>
            <a href="/programs" className='button-cp' style={{ textDecoration: 'none' , marginTop: '90px'}}>
                        <div className='button-text-cp'>
                            register today
                        </div>
                    </a>
        </div>
    </div>
    );
}