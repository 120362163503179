import React from "react";

import ProgramHero from "../components/programs/ProgramHero";
import Offerings from "../components/programs/Offerings";
import CourseGrid from "../components/programs/CourseGrid";
import Stats from "../components/home/StatsBanner";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Programs() {
    return (
        <>
        <ProgramHero />
        <Offerings />
        <CourseGrid />
        <Stats />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Programs;