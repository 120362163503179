import React from 'react';

export default function IslandAbout() {
    return (
        <div className='island-about'>
            <div className='island'>
                <div className='text-frame'>
                    <div className='header-1'>
                        One-on-One Tutoring
                    </div>
                    <div className='paragraph'>
                        Experience personalized learning with our one-on-one tutoring service! We match you with an experienced tutor who will provide tailored guidance to meet your specific needs. Whether you're looking to improve your competitive programming skills, prepare for coding interviews, or deepen your understanding of computer science concepts, our tutors are here to help. With an hourly rate of $60 per hour, you can schedule sessions at your convenience and continue lessons until you are fully satisfied with your progress. Take your programming journey to the next level with our dedicated one-on-one tutoring service. Sign up now!<br/><br/>
                    </div>
                </div>
                <a href="https://forms.gle/YfJjN439ws12GawX8" className='button-tutoring' style={{ textDecoration: 'none', display: 'inline-block', padding: '10px 20px', backgroundColor: 'black', color: 'white', transition: 'background-color 0.3s' }}>
    <div className='button-text-tutoring' style={{ fontSize: '16px', color: 'white' }}>
        Inquire now
    </div>
</a>
            </div>
        </div>
    );
}