import React from 'react';

const titleToColor = (title) => {
    let colorStyle;
    switch(title) {
        case 'Foundations':
            colorStyle = {background: '#43ABFF'};
            break;
        case 'Bronze':
            colorStyle = {background: '#BD7F46'};
            break;
        case 'Silver':
            colorStyle = {background: '#BACED2'};
            break;
        case 'Gold':
            colorStyle = {background: '#E7BC4D'};
            break;
        case 'Platinum':
            colorStyle = {background: '#507A8B'};
            break;
    }
    return colorStyle;
}

export default function SlideCard({ title, ctext, prof, lectn }) {
    return (
        <div className='slide-card'>
            <div className='top-content'>
                <div className='color-bar' style={titleToColor(title)}/>
                <div className='card-content'>
                    <div className='cheader'>
                        {title}
                    </div>
                    <div className='cparagraph'>
                        {ctext}
                    </div>
                </div>
            </div>
            <div className='bottom-content'>
                <div className='hline' style={titleToColor(title)}/>
                <div className='columns-sc'>
                    <div className='text'>
                        {prof}
                    </div>
                    <div className='text'>
                        {lectn} Lectures
                    </div>
                </div>
            </div>
        </div>
    );
}