import React from "react";

import BronzeHero from "../components/bronze/BronzeHero";
import BronzeTop from "../components/bronze/BronzeTop";
import BronzeBot from "../components/bronze/BronzeBot";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Bronze() {
    return (
        <>
        <BronzeHero />
        <BronzeTop />
        <BronzeBot />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Bronze;