import React from 'react';

export default function PageHero() {
    return (
        <div className='page-hero'>
          <div style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 50, display: 'inline-flex'}}>
            <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
            <div style={{ color: '#002626', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '400', textTransform: 'lowercase', wordWrap: 'break-word' }}>
              <a href="/home" style={{ color: '#002626' }}>Home</a>{' '}&gt; About</div>                
              <div style={{color: '#002626', fontSize: 52, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>About Ilim Code Camp</div>
            </div>
          </div>
        </div>
    );
}