import React from "react";

import FoundationHero from "../components/foundations/FoundationHero";
import Top from "../components/foundations/Top";
import Bot from "../components/foundations/Bot";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Foundations() {
    return (
        <>
        <FoundationHero />
        <Top />
        <Bot />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Foundations;