export const navItems = [
    {
      id: 1,
      title: "about",
      path: "./about",
      cName: "nav-item",
    },
    {
      id: 2,
      title: "programs",
      path: "./programs",
      cName: "nav-item",
    },
    {
      id: 3,
      title: "join us",
      path: "./jointhis",
      cName: "nav-item",
    },
    {
      id: 4,
      title: "tutoring",
      path: "./tutoring",
      cName: "nav-item",
    },
  ];
  
  export const serviceDropdown = [
    {
      id: 1,
      title: "foundations",
      path: "./foundations",
      cName: "submenu-item",
    },
    {
      id: 2,
      title: "bronze",
      path: "./bronze",
      cName: "submenu-item",
    },
    {
      id: 3,
      title: "silver",
      path: "./silver",
      cName: "submenu-item",
    },
    {
      id: 4,
      title: "gold",
      path: "./gold",
      cName: "submenu-item",
    },
    {
      id: 5,
      title: "platinum",
      path: "./platinum",
      cName: "submenu-item",
    },
  ];