import React from "react";

import GoldHero from "../components/gold/GoldHero";
import GoldTop from "../components/gold/GoldTop";
import GoldBot from "../components/gold/GoldBot";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Gold() {
    return (
        <>
        <GoldHero />
        <GoldTop />
        <GoldBot />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Gold;