import React from 'react';

import StatItem from './StatItem';

export default function StatsBanner() {
    return (
        <div className='stats'>
            <div className='grid-1x4'>
                <div className='grid-items-stats'>
                    <StatItem 
                        num={'100K'}
                        info={'Lines of code'}
                    />
                    <StatItem 
                        num={'750'}
                        info={'Problems'}
                    />
                    <StatItem 
                        num={'100+'}
                        info={'Lecture hours'}
                    />
                </div>
                <div className='header-stats'>
                    First-class resources by expert instructors
                </div>
            </div>
        </div>
    );
}