import React from 'react';

import MailLogo from '../../assets/svg/mail.svg';
import WorldLogo from '../../assets/svg/world.svg';
import IkosaederLogo from '../../assets/svg/ikosaeder.svg';

import JoinItem from './JoinItem';

export default function JoinUs() {
    return (
        <div className='join-us'>
            <div className='content-join-us'>
                <div className='subheader-join-us'>
                    get involved with ilim code camp:
                </div>
                <div className='join-items'>
                    <JoinItem 
                        title={'Subscribe'}
                        text={'to our program newsletter'}
                        icon={MailLogo}
                    />
                    <JoinItem 
                        title={'register'}
                        text={'for an information session'}
                        icon={WorldLogo}
                    />
                    <JoinItem 
                        title={'explore'}
                        text={'our programming courses'}
                        icon={IkosaederLogo}
                    />
                </div>
            </div>
        </div>
    );
}