import React from 'react';

import CMU from '../../assets/img/cmu.png';
import NEU from '../../assets/img/neu-logo.png';
import HAR from '../../assets/img/harvard.png';
import MIT from '../../assets/img/mit.png';
import STAN from '../../assets/img/stan.png';




export default function SplitInfos2() {
    return (
        <div style={{width: '100%', height: '100%', paddingLeft: 150, paddingRight: 150, paddingTop: 100, paddingBottom: 100, background: '#002626', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150, display: 'flex', boxSizing: 'border-box'}}>
        <div style={{width: '200', color: '#E1F1FD', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Our Team</div>
        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', height: 121, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                <div style={{color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Who are we?</div>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>we are a team of former USACO Platinum achievers, ICPC Qualifiers, and Putnam competitors dedicated to sharing our wealth of knowledge in competitive programming. Hailing from esteemed institutions, we have honed our skills and excelled in prestigious competitions. Now, fueled by our passion for coding and teaching, we are committed to empowering the next generation of programmers with the expertise and strategies essential for success in the competitive programming arena.</div>
            </div>
            <div style={{padding: 20, background: '#E1F1FD', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex', marginTop: '100px'}}>
                <img style={{width: 100, height: 100}} src={CMU} />
                <img style={{width: 100, height: 100}} src={NEU} />
                <img style={{width: 124.09, height: 105}} src={HAR} />
                <img style={{width: 65.48, height: 100}} src={STAN} />
                <img style={{width: 180, height: 120}} src={MIT} />
            </div>
        </div>
    </div>
    );
}