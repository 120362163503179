import React from 'react';

export default function StatItem({ num, info }) {
    return (
        <div className='stat-item'>
            <div className='stat-num'>
                {num}
            </div>
            <div className='stat-info'>
                {info}
            </div>
        </div>
    );
}