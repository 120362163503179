import React from 'react';

export default function SplitInfos3() {
    return (
        <div style={{width: '100%', height: '100%', paddingLeft: 150, paddingRight: 150, paddingTop: 100, paddingBottom: 100, background: '#002626', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150, display: 'inline-flex', boxSizing: 'border-box'}}>
    <div style={{width: 200, color: '#E1F1FD', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Our origin</div>
    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 245, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Where we started</div>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 26, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>We all remember the struggle of navigating the complex world of competitive programming as beginners, scouring the internet for reliable resources and guidance. That's why we've come together to create a platform that we wish we had when we were starting out.

Our journey began with a shared passion for coding and a desire to make quality education accessible to all aspiring programmers. Drawing from our own experiences and challenges, we've crafted a comprehensive learning environment that addresses the needs of students at every level.

</div>
        </div>
    </div>
</div>
    );
}