import React from "react";

import JoinHero from "../components/joinus/JoinHero";
import InfoParagraph from "../components/joinus/InfoParagraph";
import Offerings from "../components/joinus/Offerings";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function JoinThis() {
    return (
        <>
        <JoinHero />
        <InfoParagraph />
        <Offerings />
        <JoinUs />
        <Footer />
        </>
    );
}

export default JoinThis;