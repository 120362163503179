import React from 'react';

import SlideCard from './SlideCard.js';

export default function CourseRow() {
    return (
        <div className='course-row'>
            <div className='heading'>
                <div className='subheader'>
                    our programs
                </div>
                <div className='header'>
                    Level-up your knowledge
                </div>
            </div>
            <div className='slide-cards'>
                <SlideCard 
                    title={'Foundations'}
                    ctext={'Students will be able to solve AP Computer Science A (college undergraduate) level problems in the C++ programming language. We provide coverage across topics in algorithms, data structures, and intuition'}
                    prof= {'Beginner I'}
                    lectn={'21'}
                />
                <SlideCard 
                    title={'Bronze'}
                    ctext={'Students will be able to solve USACO Bronze level problems, delving deeper into greedy algorithms, ad-hoc, sorting, and searching.  We provide custom problem sets and solutions to help students understand the concepts.'}
                    prof= {'Beginner II'}
                    lectn={'21'}
                />
                <SlideCard 
                    title={'Silver'}
                    ctext={'Students will be able to solve USACO Silver level problems, topics include prefix sums, binary search, graph theory, and recursion. We provide custom problem sets and solutions to help students understand the concepts.'}
                    prof= {'Intermediate I'}
                    lectn={'21'}
                />
                <SlideCard 
                    title={'Gold'}
                    ctext={'Students will be able to solve USACO Gold level problems, topics include dynamic programming, shortest paths, and advanced graph theory. We provide custom problem sets and solutions to help students understand the concepts.'}
                    prof= {'Intermediate II'}
                    lectn={'21'}
                />
                <SlideCard 
                    title={'Platinum'}
                    ctext={'Students will be able to solve USACO Platinum level problems, topics include advanced data structures, flows, and geometry. We provide custom problem sets and solutions to help students understand the concepts.'}
                    prof= {'Advanced'}
                    lectn={'21'}
                />
            </div>
            <a href="/programs" className='button-cr' style={{textDecoration: 'none'}}>
                <div className='button-cr-txt'>
                    Register now
                </div>
            </a>
        </div>
    );
}