import React from 'react';

export default function BronzeTop() {
    return (
        <div style={{paddingTop: 50, paddingBottom: 100, paddingLeft: 150, paddingRight: 150, background: '#BACED2', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex'}}>
    <div style={{flex: '1 1 0', alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 184, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Pass USACO Silver</div>
        </div>
        <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Program cost:</div>
            <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '400', textTransform: 'lowercase', wordWrap: 'break-word'}}>$1,000</div>
        </div>
    </div>
    <div style={{width: 425, alignSelf: 'stretch', padding: 50, background: '#E1F1FD', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', borderRight: '1px black solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 263, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
            <div style={{color: '#002626', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Quick Facts:</div>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Proficiency:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Commitment:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Duration:          </div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Dates:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Time:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Location:</div>
                    <div style={{color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Capacity:</div>
                </div>
                <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intermediate I</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>10 hours/week</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>24 lectures          </div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>September 2nd - October 25th</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>MWF: 6:00PM - 8:00PM</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Video Conference</div>
                    <div style={{alignSelf: 'stretch', color: '#002626', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>50</div>
                </div>
            </div>
        </div>
        <a href="https://buy.stripe.com/9AQ5nU4nV0Ug45G5km" className='button-cr' style={{textDecoration: 'none'}}>
                <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>register now</div>
            </a>
    </div>
</div>
    );
}