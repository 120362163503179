import React from "react";

import PlatHero from "../components/plat/PlatHero";
import PlatTop from "../components/plat/PlatTop";
import PlatBot from "../components/plat/PlatBot";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Platinum() {
    return (
        <>
        <PlatHero />
        <PlatTop />
        <PlatBot />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Platinum;