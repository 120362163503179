import React from 'react';

import WideLogo from '../../assets/svg/logo_wide_v1_white.svg';

export default function Footer() {
    return (
        <div className='footer'>
            <div className='columns-footer'>
                <div className='text-content-footer'>
                    <div className='big-text-footer'>
                        Unlock Your Potential with Our Competitive Programming Bootcamp
                    </div>
                    <div className='logo-wide-v1'>
                        <img src={WideLogo} alt = "Logo"/>
                    </div>
                </div>
                <div className='vline-footer'/>
                <div className='link-columns-footer'>
                    <div className='column-footer'>
                        <div className='subheader-footer'>
                            company
                        </div>
                        <div className='links-footer'>
                            <div className='link-footer'>
                                <a href="/about" style={{ textDecoration: 'none' , color: 'white'}}>About</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/programs" style={{ textDecoration: 'none' , color: 'white'}}>Programs</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/jointhis" style={{ textDecoration: 'none' , color: 'white'}}>Team</a>
                            </div>
                            <div className='link-footer'>
                                FAQs
                            </div>
                        </div>
                    </div>
                    <div className='column-footer'>
                        <div className='subheader-footer'>
                            programs
                        </div>
                        <div className='links-footer'>
                            <div className='link-footer'>
                                <a href="/foundations" style={{ textDecoration: 'none' , color: 'white'}}>Foundations</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/bronze" style={{ textDecoration: 'none' , color: 'white'}}>Bronze</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/silver" style={{ textDecoration: 'none' , color: 'white'}}>Silver</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/gold" style={{ textDecoration: 'none' , color: 'white'}}>Gold</a>
                            </div>
                            <div className='link-footer'>
                                <a href="/platinum" style={{ textDecoration: 'none' , color: 'white'}}>Platinum</a>
                            </div>
                            <div className='link-footer'>
                                Tech Interview Prep
                            </div>
                            <div className='link-footer'>
                                Private Tutoring
                            </div>
                        </div>
                    </div>
                    <div className='column-footer'>
                        <div className='subheader-footer'>
                            support
                        </div>
                        <div className='links-footer'>
                            <div className='link-footer'>
                                <a href="/jointhis" style={{ textDecoration: 'none' , color: 'white'}}>Join Us</a>
                            </div>
                            <div className='link-footer'>
                                Help Center
                            </div>
                            <div className='link-footer'>
                                Privacy Center
                            </div>
                            <div className='link-footer'>
                                <a href="mailto:ilimcodecamp@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-links-footer'>
                <div className='bottom-links-hline-footer'/>
                <div className='bottom-links-content-columns-footer'>
                    <div className='left-links-footer'>
                        <div className='link-text-footer'>
                            Privacy Policy
                        </div>
                        <div className='bottom-links-vline-footer'/>
                        <div className='link-text-footer'>
                            Terms and Conditions
                        </div>
                        <div className='bottom-links-vline-footer'/>
                        <div className='link-text-footer'>
                            Do Not Sell My Personal Information
                        </div>
                    </div>
                    <div className='right-text-footer'>
                        Made with ❤️ in Boston © 2023 Ilim Code Camp
                    </div>
                </div>
            </div>
        </div>
    );
}