import React from 'react';

export default function SplitInfos() {
    return (
        <div style={{width: '100%', height: 'fit-content', paddingLeft: 150, paddingRight: 150, paddingTop: 100, paddingBottom: 100, background: '#002626', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150, display: 'flex', boxSizing: 'border-box'}}>
            <div style={{width: '200', color: '#E1F1FD', fontSize: 38, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Mission</div>
            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', height: 121, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                    <div style={{color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Prestigious Program For The Bright Minds Of Our Future</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>At Ilim, our mission is to cultivate the geniuses of the next generation by providing training in competitive programming. We believe that by nurturing exceptional talent and fostering critical thinking skills, we can empower individuals to not only excel in their academic pursuits but also contribute meaningfully to society.</div>
                </div>
                <div style={{marginTop: '50px'}}> {/* Adjusted button placement */}
                    <a href="/programs" className='button-cr' style={{textDecoration: 'none'}}>
                        <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>explore our programs</div>
                    </a>
                </div>
            </div>
        </div>
    );
}
