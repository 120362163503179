import React from "react";

import TutoringHero from "../components/tutoring/TutoringHero";
import IslandAbout3 from "../components/home/IslandAbout3";
import JoinUs from "../components/home/JoinUs";
import Footer from "../components/home/Footer";

function Tutoring() {
    return (
        <>
        <TutoringHero />
        <IslandAbout3 />
        <JoinUs />
        <Footer />
        </>
    );
}

export default Tutoring;