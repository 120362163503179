import React from 'react';

export default function IslandAbout() {
    return (
        <div className='island-about'>
            <div className='island'>
                <div className='text-frame'>
                    <div className='header-1'>
                        About Our School
                    </div>
                    <div className='paragraph'>
                    Welcome to our competitive programming bootcamp tailored specifically for USACO aspirants! At our school, we pride ourselves on offering an unparalleled learning experience led by a team of elite competitive programmers. Our instructors not only have a proven track record of excelling in USACO competitions but also boast achievements in ICPC and IOI.
                    <br/><br/>
                    Our courses offer more than just preparation for USACO. The skills you'll develop with us are transferrable and beneficial for improving competitive programming skills in general. Whether you aspire to excel on platforms like Codeforces, participate in hackathons, or prepare for technical interviews at top tech companies, our bootcamp provides the solid foundation and advanced techniques necessary for success.
                    </div>
                </div>
                <button className='button-abt'>
                    <a href="/about" class="button-abt-txt" style={{textDecoration: 'none'}}>
                        Learn more
                    </a>
                </button>
            </div>
        </div>
    );
}