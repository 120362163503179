import React from 'react';

export default function BronzeBot() {
    return (
        <div style={{paddingTop: 75, paddingBottom: 150, paddingLeft: 150, paddingRight: 150, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 75, display: 'inline-flex'}}>
    
    <div style={{alignSelf: 'stretch', paddingBottom: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex'}}>
        <div style={{width: 565, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 30, display: 'inline-flex'}}>
            <div style={{alignSelf: 'stretch', height: 62, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Outcomes</div>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>By the end of the Bronze course, students will be able to:</div>
            </div>
            <div style={{alignSelf: 'stretch', height: 446, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Simulate real-world problems with code</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Approach and solve problems using competitive programming strategies and techniques.</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Breaking down complex problems into manageable components using logic and programming techniques</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Use recursive techniques to search trees, sort, and more</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Utilize data structures to solve algorithmic problems</div>
                </div>
                <div style={{alignSelf: 'stretch', padding: 10, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                    <div style={{paddingTop: 5, paddingBottom: 5, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{width: 12, height: 12, background: 'white', borderRadius: 9999}} />
                    </div>
                    <div style={{flex: '1 1 0', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Know when to utilize greedy algorithms or use recursive algorithms</div>
                </div>
            </div>
        </div>
        <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100, display: 'inline-flex'}}>
            <div style={{width: 418, height: 175, background: '#002626', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', height: 85, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Stats</div>
                    <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Ilim offers tremendous value to our students. Foundations alone consists of:</div>
                </div>
                <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>100K</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Lines of code</div>
                    </div>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>42hr</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Of lecture content</div>
                    </div>
                    <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 32, fontFamily: 'Ubuntu Mono', fontWeight: '400', wordWrap: 'break-word'}}>14</div>
                        <div style={{color: 'white', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>Assignments</div>
                    </div>
                </div>
            </div>
            <div style={{alignSelf: 'stretch', height: 197, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Sample Materials</div>
                <div style={{alignSelf: 'stretch', height: 148, padding: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
                    <div style={{padding: 20, background: '#FFE500', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 135, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>course syllabus</div>
                    </div>
                    <div style={{width: 175, padding: 20, background: '#FFE500', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 5, justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{width: 117, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>course slides</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
        <div style={{alignSelf: 'stretch', height: 62, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Program Schedule</div>
            <div style={{alignSelf: 'stretch', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>See the topics covered and schedule for the Foundations course below.</div>
        </div>
        <div style={{alignSelf: 'stretch',padding: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 1</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Course Orientation</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Time complexity</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Data structures and memory allocation</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 2</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intro to Simulation</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Simulation Practice</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Simulation Quiz</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 3</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Rectangle Geometry</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Complete Search</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Recursive Complete Search</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 4</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Backtracking</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Complete Search Quiz</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Sorting Introduction</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 5</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Sorting Quiz</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Sets and Maps</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Ad Hoc</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 6</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Greedy Algorithms</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Intro to Graphs</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Graphs Practice</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: 265, background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{alignSelf: 'stretch', padding: 10, background: '#BD7F46', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                        <div style={{color: 'white', fontSize: 24, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word'}}>Week 7</div>
                    </div>
                    <div style={{alignSelf: 'stretch', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', background: 'white', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>MON</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>WED</div>
                                <div style={{color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '700', wordWrap: 'break-word'}}>FRI</div>
                            </div>
                            <div style={{alignSelf: 'stretch', transformOrigin: '0 0', borderLeft: 'none', borderRight: '1px black solid', borderTop: 'none', borderBottom: 'none' }}></div>
                            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 7, display: 'inline-flex'}}>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Graphs Quiz</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Final Exam Practice</div>
                                <div style={{alignSelf: 'stretch', color: 'black', fontSize: 14, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Final Exam</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{alignSelf: 'stretch', height: 97, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex'}}>
            <div style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'PT Sans', fontWeight: '400', wordWrap: 'break-word'}}>Ready to level-up your math and programming skills?</div>
            <a href="https://buy.stripe.com/4gwbMig6D0UggSs145" className='button-cr' style={{textDecoration: 'none'}}>
                <div style={{ width: 180, height: 14, textAlign: 'center', color: '#002626', fontSize: 18, fontFamily: 'Ubuntu Mono', fontWeight: '700', textTransform: 'lowercase', wordWrap: 'break-word' }}>register now</div>
            </a>
        </div>
    </div>
    
</div>
    );
}