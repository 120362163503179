import React from 'react';

export default function TextItem({ header, content }) {
    return (
        <div className='text-item'>
            <div className='item-header'>
                {header}
            </div>
            <div className='item-text'>
                {content}
            </div>
        </div>
    );
}