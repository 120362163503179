import React from 'react';

export default function TutoringInfo() {
    return (
        <div className='tutoring'>
            <div className='columns-tutoring'>
                <div className='text-content-tutoring'>
                    <div className='heading-tutoring'>
                        <div className='subheader-tutoring'>
                            tailored studies
                        </div>
                        <div className='header-tutoring'>
                            1-on-1 learning options
                        </div>
                    </div>
                    <div className='content-tutoring'>
                        <div className='text-tutoring'>
                            Get one-on-one tutoring sessions with our experienced instructors. We offer personalized tutoring sessions for students who want to learn at their own pace. Our instructors will work with you to create a custom curriculum that fits your needs.
                        </div>
                        <a href="/tutoring" className='button-tutoring' style={{ textDecoration: 'none' }}>
                            <div className='button-text-tutoring'>
                                Inquire now
                            </div>
                        </a>
                    </div>
                </div>
                <div className='media-tutoring'>
                </div>
            </div>
        </div>
    );
}